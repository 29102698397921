/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import "./src/styles/global.css";

export const onInitialClientRender = () => {
  // This is where we can init google tag manager ( https://www.gatsbyjs.com/blog/making-the-gatsby-homepage-more-performant/ # Removing excess dependencies and code)

  if (window.dataLayer !== undefined) {
    const events = ["scroll", "mousemove", "keydown", "click", "touchstart"];

    const scrollListener = () => {
      events.forEach(event => {
        window.removeEventListener(event, scrollListener);
      });
      setTimeout(() => {
        window.dataLayer.push({
          event: "initial-client-render",
        });
      }, 200);
    };

    events.forEach(event => {
      window.addEventListener(event, scrollListener);
    });
  }
};
